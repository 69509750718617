<template>
  <PageCard
    pageName="FIN VendorFit"
    :iconClass="['fa', 'icon-screen-smartphone', 'fa-lg']"
    :showHeader="false"
  >
    <template slot="page_content">
      <div
        :class="
          partnerSite ? ['homepage-content partner-site'] : ['homepage-content']
        "
      >
        <section class="  solution-section">
          <div class="">
            <div class="section container-margin" id="1">
              <FeatureDetailsCard
                title="FIN VendorFit"
                description1="Leverage FIN VendorFit to rank core processing vendor-product fit for any U.S. financial institution. The regression analysis integrates over 100 independent variables ranging from asset size to technology purchase behavior."
                description2="Utilize the scoring for new sales to identify higher probability conversion candidates or account management to ID at-risk clients."
                button1Text="See Use Cases"
                :button1Link="usecaseLink"
                :galleryImages="VC_SOL_FIN_V"
              />
            </div>
          </div>
        </section>
      </div>
    </template>
  </PageCard>
</template>

<script>
// global
import { mapState, mapGetters } from 'vuex'
// layout
import PageCard from '@/modules/core/components/layouts/PageCard'
import FeatureDetailsCard from '../components/layouts/FeatureDetailsCard'

export default {
  name: 'AboutVendorFit',
  components: {
    PageCard,
    FeatureDetailsCard
  },
  data() {
    return {
      VC_SOL_FIN_V: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_V1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_V2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_V3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_V4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_V5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_V6.png'
      ]
    }
  },
  created() {
    document.title = 'FIN Advisor'
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    }),
    ...mapGetters('Authentication', ['isFiMenu']),
    usecaseLink() {
      return this.isFiMenu
        ? '/technology_analytics/about'
        : '/technographics/about'
    }
  }
}
</script>
<style lang="scss" src="../scss/infoCards.scss"></style>
<style lang="scss" scoped>
.homepage-content {
  background-size: cover;
}
</style>
